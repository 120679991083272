<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Transfer Script
      </h1>

      <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
        <!-- component -->
        <form method="post" @submit.prevent="addNew">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>

            <div class="flex items-center justify-between">
              <a
                v-on:click="doSomething($event)"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
              >
                Transfer Script
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    doSomething: function doSomething(ev) {
      console.log(ev); // MouseEvent { ... }
      //alert("helllo");
      //get(){
      this.$axios
        .get(`https://64.227.185.57/mymail.php`)
        .then(res => {
          console.log(res);
          alert("Your Bot hitting now.");
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
      alert("Thank You. Your Request is Received.");
      //}
    }
  }
};
</script>
